<script>
// import NavBar from "@/components/nav-bar";
import RightBar from "@/components/right-bar";
import Footer from "@/components/footer";

export default {
  data() {
    return {
      usertype: localStorage.getItem('usertype'),
      username: localStorage.getItem('username') + " ("+localStorage.getItem('usertype')+")",
    };
  },
  watch: {
    $route: {
      handler: "onRoutechange",
      immediate: true,
      deep: true,
    },
  },
  methods: {
    onRoutechange(ele) {
      this.initActiveMenu(ele.path);
    }, initFullScreen() {
      document.body.classList.toggle("fullscreen-enable");
      if (
        !document.fullscreenElement &&
        /* alternative standard method */
        !document.mozFullScreenElement &&
        !document.webkitFullscreenElement
      ) {
        // current working methods
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) {
          document.documentElement.webkitRequestFullscreen(
            Element.ALLOW_KEYBOARD_INPUT
          );
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        }
      }
    },
    initActiveMenu(ele) {
      setTimeout(() => {
        if (document.querySelector("#navbar-nav")) {
          let a = document
            .querySelector("#navbar-nav")
            .querySelector('[href="' + ele + '"]');

          if (a) {
            a.classList.add("active");
            let parentCollapseDiv = a.closest(".collapse.menu-dropdown");
            if (parentCollapseDiv) {
              parentCollapseDiv.classList.add("show");
              parentCollapseDiv.parentElement.children[0].classList.add(
                "active"
              );
              parentCollapseDiv.parentElement.children[0].setAttribute(
                "aria-expanded",
                "true"
              );
              if (
                parentCollapseDiv.parentElement.closest(
                  ".collapse.menu-dropdown"
                )
              ) {
                parentCollapseDiv.parentElement
                  .closest(".collapse")
                  .classList.add("show");
                if (
                  parentCollapseDiv.parentElement.closest(".collapse")
                    .previousElementSibling
                )
                  parentCollapseDiv.parentElement
                    .closest(".collapse")
                    .previousElementSibling.classList.add("active");
              }
            }
          }
        }
      }, 1000);
    },
  },
  components: {
    // NavBar,
    RightBar,
    Footer
  },
};
</script>

<template>
  <div>
    <div id="layout-wrapper">
      <!-- <NavBar /> -->
      <!-- ========== App Menu ========== -->
      <div class="app-menu navbar-menu" style="margin-top: 0px;">
        <!-- LOGO -->
         
        
        <div id="scrollbar">
          <div class="container-fluid">
            <ul class="navbar-nav h-100" id="navbar-nav">
              <li class="nav-item">
                <router-link class="nav-link menu-link" to="/Dashboard">
                 
                <i class="ri-command-fill" style="color: chocolate;"></i><span style="padding-left: 0px;color: brown;font-size: 19px;">PregetSoft &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link menu-link" to="/Dashboard">
                   Dashboard
                </router-link>
              </li>
             
              <li class="nav-item">
                <router-link class="nav-link menu-link" to="/projects" v-if="usertype == 'Super Admin'  || usertype == 'Admin'">
                 
                  <span v-if="usertype !== 'Super Admin'">My Projects</span>
                  <span v-if="usertype == 'Super Admin'">All Projects</span>
                </router-link>
                <router-link class="nav-link menu-link" to="/projects" v-if="usertype == 'Bidder'">
                  

                  <span>All Projects</span>
                </router-link>

              </li>
              <li class="nav-item" v-if="usertype == 'Super Admin' || usertype == 'Admin'">
                <a href="#sidebarpayment" class="nav-link" data-bs-toggle="collapse" role="button"
                  aria-expanded="false" aria-controls="sidebarpayment" >
                 Payments
                </a>
                <div class="collapse menu-dropdown" id="sidebarpayment">
                  <ul class="nav nav-sm flex-column">
                    <li class="nav-item">
                      <router-link to="/categories/Payment-Holder-Name" class="nav-link" >
                        Payment Holder Name
                      </router-link>
                      <!-- <a href="/categories/Payment Holder Name" class="nav-link" >
                       Payment Holder Name
                      </a> -->
                    </li>
                    <li class="nav-item">
                      <router-link to="/payments/payment_accounts" class="nav-link" >
                        Manage Payment Accounts
                      </router-link>
                    </li>
                   
                  </ul>
                </div>
              </li>
              <li class="nav-item" v-if="usertype == 'Super Admin'  || usertype == 'Admin'">
                <a href="#sidebarexpenses" class="nav-link" data-bs-toggle="collapse" role="button"
                  aria-expanded="false" aria-controls="sidebarexpenses" >
                 Expenses
                </a>
                <div class="collapse menu-dropdown" id="sidebarexpenses">
                  <ul class="nav nav-sm flex-column">
                    <li class="nav-item" v-if="usertype == 'Super Admin'">
                      <router-link to="/categories/Expenses-Head" class="nav-link" >
                        Expenses Head
                      </router-link>
                      <!-- <a href="/categories/Expenses Head" class="nav-link" >
                       Expenses Head
                      </a> -->
                    </li>
                    <li class="nav-item" v-if="usertype == 'Super Admin'">
                      <router-link to="/Expenses/manage" class="nav-link" >
                        Manage Expenses
                      </router-link>
                    </li>
                    
                   
                  </ul>
                  
                </div>
              </li>
              <!-- start of setting -->
              <div class="dropdown ms-sm-3 header-item">
                <button type="button" style="padding-top: 0px;box-shadow: none !important;" class="btn" id="page-header-user-dropdown" data-bs-toggle="dropdown"
                  aria-haspopup="true" aria-expanded="false">
                  <span class="d-flex">
                    <!-- :src="imgsrc" -->
                   
                     {{username}}  <i class="ri-arrow-down-s-line"></i>

                    <!-- <img class="rounded-circle header-profile-user" 
                    alt="" src="#" /> -->
                    <span class="text-start ms-xl-2">
                      <span class="d-none d-xl-inline-block ms-1 fw-medium user-name-text"></span>
                      <!-- <span class="d-none d-xl-block ms-1 fs-12 text-muted user-name-sub-text">Founder</span> -->
                    </span>
                  </span>
                </button>

                <div class="dropdown-menu dropdown-menu-end">
                  <!-- item-->
                  <h6 class="dropdown-header">Welcome <span style="color:blue"> {{username}}!</span></h6>
                  <!-- <router-link class="dropdown-item" to="/pages/profile"><i
                    class="mdi mdi-account-circle text-muted fs-16 align-middle me-1"></i>
                  <span class="align-middle">Profile</span>
                </router-link> -->
                  <!-- <router-link class="dropdown-item" to="/chat">
                  <i class=" mdi mdi-message-text-outline text-muted fs-16 align-middle me-1"></i>
                  <span class="align-middle">Messages</span></router-link>
                <router-link class="dropdown-item" to="/apps/tasks-kanban">
                  <i class="mdi mdi-calendar-check-outline text-muted fs-16 align-middle me-1"></i>
                  <span class="align-middle">Taskboard</span></router-link>
                <router-link class="dropdown-item" to="/pages/faqs"><i
                    class="mdi mdi-lifebuoy text-muted fs-16 align-middle me-1"></i>
                  <span class="align-middle">Help</span></router-link>
                <div class="dropdown-divider"></div> -->
                  <!-- <router-link class="dropdown-item" to="/pages/profile"><i
                    class="mdi mdi-wallet text-muted fs-16 align-middle me-1"></i>
                  <span class="align-middle">Balance : <b>$5971.67</b></span></router-link> -->
                  <router-link class="dropdown-item" to="/pages/profile-setting">
                    <span class="align-middle">Settings</span>
                  </router-link>
                  <router-link v-if="usertype == 'Super Admin' || usertype == 'Task Manager'  || usertype == 'Admin'" class="dropdown-item" to="/users">
                    <span class="align-middle">Users</span>
                  </router-link>
                  <router-link v-if="usertype == 'Super Admin'  || usertype == 'Admin'" class="dropdown-item" to="/profiles">
                    <span class="align-middle">Profiles</span>
                  </router-link>

                   
           
                  <!-- <router-link class="dropdown-item" to="/auth/lockscreen-basic"><i
                    class="mdi mdi-lock text-muted fs-16 align-middle me-1"></i>
                  <span class="align-middle">Lock screen</span></router-link> -->
                  <a class="dropdown-item" href="/logout">
                    <span class="align-middle" data-key="t-logout">Logout</span></a>
                </div>
              </div>
              <!-- end of setting -->

               <div class="ms-1 header-item d-none d-sm-flex">
              <button type="button" class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle shadow-none"
                data-toggle="fullscreen" @click="initFullScreen">
                <i class="bx bx-fullscreen fs-22"></i>
              </button>
            </div>
            </ul>
            
          </div>
          <!-- Sidebar -->
        </div>
        <!-- Left Sidebar End -->
        <!-- Vertical Overlay-->
        <div class="vertical-overlay"></div>
      </div>
      <!-- ============================================================== -->
      <!-- Start Page Content here -->
      <!-- ============================================================== -->

      <div class="main-content">
        <div class="page-content" style="background-color: white;margin-top: 20px;">
          <!-- Start Content-->
          <div class="container-fluid" style="max-width: 100% !important;">
            <slot />
          </div>
        </div>
        <Footer />
      </div>
      <RightBar />
    </div>
  </div>
</template>