<script>
import {
  layoutComputed
} from "@/state/helpers";
import {
  SimpleBar
} from "simplebar-vue3";

export default {
  components: {
    SimpleBar
  },
  data() {
    return {
      settings: {
        minScrollbarLength: 60,
      },
      templatesjson: [
        {
          "Id": 1,
          "Category": "Article",
          "Template Name": "Article Ideas",
          "Prompt": "Write ideas for an article about"
        },
        {
          "Id": 2,
          "Category": "Article",
          "Template Name": "Article Title",
          "Prompt": "Write a title for an article about"
        },
        {
          "Id": 3,
          "Category": "Article",
          "Template Name": "Article Outline",
          "Prompt": "Write an outline for an article about"
        },
        {
          "Id": 4,
          "Category": "Article",
          "Template Name": "Article Intro",
          "Prompt": "Write an introduction for an article about"
        },
        {
          "Id": 5,
          "Category": "Article",
          "Template Name": "Article Conclusion",
          "Prompt": "Write a conclusion for an article about"
        },
        {
          "Id": 6,
          "Category": "Article",
          "Template Name": "Bullet Point to Article Section",
          "Prompt": "Write a paragraph about"
        },
        {
          "Id": 7,
          "Category": "Article",
          "Template Name": "Keyword Generator",
          "Prompt": "Write keywords about"
        },
        {
          "Id": 8,
          "Category": "Product",
          "Template Name": "Product Description",
          "Prompt": "Write a description for"
        },
        {
          "Id": 9,
          "Category": "Ad",
          "Template Name": "Ad Headline",
          "Prompt": "Write an ad headline for"
        },
        {
          "Id": 10,
          "Category": "Ad",
          "Template Name": "Ad Link Description",
          "Prompt": "Write a link description for an ad about"
        },
        {
          "Id": 11,
          "Category": "Ad",
          "Template Name": "Ad Outline",
          "Prompt": "Write an outline for an ad about"
        },
        {
          "Id": 12,
          "Category": "Ad",
          "Template Name": "Ad Description",
          "Prompt": "Write a description for an ad about"
        },
        {
          "Id": 13,
          "Category": "Startup",
          "Template Name": "Brand Mission",
          "Prompt": "Write a brand mission for a startup about"
        },
        {
          "Id": 14,
          "Category": "Startup",
          "Template Name": "Brand Voice",
          "Prompt": "Brainstorm words that characterize a brand about"
        },
        {
          "Id": 15,
          "Category": "Startup",
          "Template Name": "Motto Generator",
          "Prompt": "Write a motto for a brand about"
        },
        {
          "Id": 16,
          "Category": "Startup",
          "Template Name": "Business Description",
          "Prompt": "Describe a business about"
        },
        {
          "Id": 17,
          "Category": "Startup",
          "Template Name": "Value Proposition",
          "Prompt": "Write a value proposition for the following description:"
        },
        {
          "Id": 18,
          "Category": "Website",
          "Template Name": "Call To Action",
          "Prompt": "Write a call to action for a product about"
        },
        {
          "Id": 19,
          "Category": "Website",
          "Template Name": "Event Copy",
          "Prompt": "Write an event promotion about"
        },
        {
          "Id": 20,
          "Category": "Website",
          "Template Name": "Landing Page Hero Text",
          "Prompt": "Write a hero text for a landing page about"
        },
        {
          "Id": 21,
          "Category": "Website",
          "Template Name": "Meta Description",
          "Prompt": "Write a meta description for a website about"
        },
        {
          "Id": 22,
          "Category": "Website",
          "Template Name": "Microcopy",
          "Prompt": "Write a microcopy"
        },
        {
          "Id": 23,
          "Category": "Website",
          "Template Name": "Question Generator",
          "Prompt": "Write an engaging question for an audience about"
        },
        {
          "Id": 24,
          "Category": "Website",
          "Template Name": "Subheader",
          "Prompt": "Write a subheader to compliment a hero text about"
        },
        {
          "Id": 25,
          "Category": "Website",
          "Template Name": "Testimonial",
          "Prompt": "Write an engaging testimonial about"
        },
        {
          "Id": 26,
          "Category": "Email",
          "Template Name": "Cancellation Email",
          "Prompt": "Write a cancellation email for"
        },
        {
          "Id": 27,
          "Category": "Email",
          "Template Name": "Subject Line",
          "Prompt": "Write a catchy email subject line for"
        },
        {
          "Id": 28,
          "Category": "Email",
          "Template Name": "Confirmation Email",
          "Prompt": "Write a confirmation email for"
        },
        {
          "Id": 29,
          "Category": "Email",
          "Template Name": "Follow-up Email",
          "Prompt": "Write a follow-up email for after"
        },
        {
          "Id": 30,
          "Category": "Email",
          "Template Name": "Thank-You Note",
          "Prompt": "Write a heartfelt thank-you note for"
        },
        {
          "Id": 31,
          "Category": "Email",
          "Template Name": "Welcome Email",
          "Prompt": "Write a welcome email for"
        },
        {
          "Id": 32,
          "Category": "Social Media",
          "Template Name": "Youtube Ideas",
          "Prompt": "Generate youtube ideas for"
        },
        {
          "Id": 33,
          "Category": "Social Media",
          "Template Name": "Hashtag Generator",
          "Prompt": "Generate hashtags for"
        },
        {
          "Id": 34,
          "Category": "Social Media",
          "Template Name": "Video Hook",
          "Prompt": "Write a hook for a video about"
        },
        {
          "Id": 35,
          "Category": "Social Media",
          "Template Name": "Instagram Caption",
          "Prompt": "Write an Instagram caption for a photo about"
        },
        {
          "Id": 36,
          "Category": "Social Media",
          "Template Name": "Relatable Experience",
          "Prompt": "Write a relatable experience about"
        },
        {
          "Id": 37,
          "Category": "Social Media",
          "Template Name": "Short Hook",
          "Prompt": "Write a short but powerful hook about"
        },
        {
          "Id": 38,
          "Category": "Social Media",
          "Template Name": "Video Ideas",
          "Prompt": "Brainstorm topics for a video about"
        },
        {
          "Id": 39,
          "Category": "Social Media",
          "Template Name": "Youtube Video Description",
          "Prompt": "Write a description for a Youtube video about"
        },
        {
          "Id": 40,
          "Category": "Social Media",
          "Template Name": "Youtube Video Title",
          "Prompt": "Write a title for a Youtube video about"
        },
        {
          "Id": 41,
          "Category": "General",
          "Template Name": "Analogy Generate",
          "Prompt": "Write a relatable analogy about"
        },
        {
          "Id": 42,
          "Category": "General",
          "Template Name": "Cliffhanger",
          "Prompt": "Write a cliffhanger for a story about"
        },
        {
          "Id": 43,
          "Category": "General",
          "Template Name": "Essay Intro",
          "Prompt": "Write an essay intro about"
        },
        {
          "Id": 44,
          "Category": "General",
          "Template Name": "Essay Outline",
          "Prompt": "Write an essay outline about"
        },
        {
          "Id": 45,
          "Category": "General",
          "Template Name": "Simplify",
          "Prompt": "Simplify the following text:"
        },
        {
          "Id": 46,
          "Category": "General",
          "Template Name": "Rewrite",
          "Prompt": "Rewrite the following text:"
        },
        {
          "Id": 47,
          "Category": "General",
          "Template Name": "Summarize",
          "Prompt": "Summarize this for a second-grade student:"
        },
        {
          "Id": 48,
          "Category": "General",
          "Template Name": "Creative Story",
          "Prompt": "Write a creative story about"
        },
        {
          "Id": 49,
          "Category": "Brainstorming",
          "Template Name": "Growth Ideas",
          "Prompt": "Brainstorm tactical growth strategies about"
        },
        {
          "Id": 50,
          "Category": "Brainstorming",
          "Template Name": "Name Generator",
          "Prompt": "Brainstorm names for a startup about"
        },
        {
          "Id": 51,
          "Category": "Brainstorming",
          "Template Name": "Business Ideas",
          "Prompt": "Brainstorm new business ideas about"
        },
        {
          "Id": 52,
          "Category": "Personal",
          "Template Name": "Birthday Card",
          "Prompt": "Write a personal birthday card for someone I'm grateful for"
        },
        {
          "Id": 53,
          "Category": "Personal",
          "Template Name": "Love Letter",
          "Prompt": "Write a love letter for"
        },
        {
          "Id": 54,
          "Category": "Personal",
          "Template Name": "Resume Bullet Points",
          "Prompt": "Write bullet points for a resume about"
        },
        {
          "Id": 55,
          "Category": "Personal",
          "Template Name": "Shower Thoughts",
          "Prompt": "Brainstorm a unique shower thought about"
        }
      ],
    };
  },
  computed: {
    ...layoutComputed,
    layoutType: 'twocolumn',

  },

  watch: {
    $route: {
      handler: "onRoutechange",
      immediate: true,
      deep: true,
    },
  },

  mounted() {
    if (document.querySelectorAll(".navbar-nav .collapse")) {
      let collapses = document.querySelectorAll(".navbar-nav .collapse");
      collapses.forEach((collapse) => {
        // Hide sibling collapses on `show.bs.collapse`
        collapse.addEventListener("show.bs.collapse", (e) => {
          e.stopPropagation();
          let closestCollapse = collapse.parentElement.closest(".collapse");
          if (closestCollapse) {
            let siblingCollapses =
              closestCollapse.querySelectorAll(".collapse");
            siblingCollapses.forEach((siblingCollapse) => {
              if (siblingCollapse.classList.contains("show")) {
                let aChild = siblingCollapse.parentNode.firstChild;
                if (aChild.hasAttribute("aria-expanded")) {
                  aChild.setAttribute("aria-expanded", "false");
                }
                siblingCollapse.classList.remove("show");
              }
            });
          } else {
            let getSiblings = (elem) => {
              // Setup siblings array and get the first sibling
              let siblings = [];
              let sibling = elem.parentNode.firstChild;
              // Loop through each sibling and push to the array
              while (sibling) {
                if (sibling.nodeType === 1 && sibling !== elem) {
                  siblings.push(sibling);
                }
                sibling = sibling.nextSibling;
              }
              return siblings;
            };
            let siblings = getSiblings(collapse.parentElement);
            siblings.forEach((item) => {
              if (item.childNodes.length > 2)
                item.firstElementChild.setAttribute("aria-expanded", "false");
              let ids = item.querySelectorAll("*[id]");
              ids.forEach((item1) => {
                let aChild = item1.parentNode.firstChild;
                if (aChild.hasAttribute("aria-expanded")) {
                  aChild.setAttribute("aria-expanded", "false");
                }
                item1.classList.remove("show");
                if (item1.childNodes.length > 2) {
                  let val = item1.querySelectorAll("ul li a");

                  val.forEach((subitem) => {
                    if (subitem.hasAttribute("aria-expanded"))
                      subitem.setAttribute("aria-expanded", "false");
                  });
                }
              });
            });
          }
        });

        // Hide nested collapses on `hide.bs.collapse`
        collapse.addEventListener("hide.bs.collapse", (e) => {
          e.stopPropagation();
          let childCollapses = collapse.querySelectorAll(".collapse");
          childCollapses.forEach((childCollapse) => {
            let childCollapseInstance = childCollapse;
            childCollapseInstance.hide();
          });
        });
      });
    }
  },

  methods: {
    onRoutechange(ele) {
      this.initActiveMenu(ele.path);
      if (document.getElementsByClassName("mm-active").length > 0) {
        const currentPosition = document.getElementsByClassName("mm-active")[0].offsetTop;
        if (currentPosition > 500)
          if (this.$refs.isSimplebar)
            this.$refs.isSimplebar.value.getScrollElement().scrollTop = currentPosition + 300;
      }
    },

    initActiveMenu(ele) {
      setTimeout(() => {
        if (document.querySelector("#navbar-nav")) {
          let a = document.querySelector("#navbar-nav").querySelector('[href="' + ele + '"]');

          if (a) {
            a.classList.add("active");
            let parentCollapseDiv = a.closest(".collapse.menu-dropdown");
            if (parentCollapseDiv) {
              parentCollapseDiv.classList.add("show");
              parentCollapseDiv.parentElement.children[0].classList.add("active");
              parentCollapseDiv.parentElement.children[0].setAttribute("aria-expanded", "true");
              if (parentCollapseDiv.parentElement.closest(".collapse.menu-dropdown")) {
                parentCollapseDiv.parentElement.closest(".collapse").classList.add("show");
                if (parentCollapseDiv.parentElement.closest(".collapse").previousElementSibling)
                  parentCollapseDiv.parentElement.closest(".collapse").previousElementSibling.classList.add(
                    "active");
              }
            }
          }
        }
      }, 0);
    },
  },
};
</script>

<template>
  <div class="container-fluid">
    <div id="two-column-menu"></div>

    <template v-if="layoutType === 'twocolumn'">
      <SimpleBar class="navbar-nav" id="navbar-nav">
        <li class="menu-title">
          <span data-key="t-menu"> {{ $t("t-menu") }}</span>
        </li>
        <li class="nav-item">
          <a class="nav-link menu-link" href="#sidebarDashboards" data-bs-toggle="collapse" role="button"
            aria-expanded="false" aria-controls="sidebarDashboards">
            <i class="ri-dashboard-2-line"></i>
            <span data-key="t-dashboards"> {{ $t("t-dashboards") }}</span>
          </a>
          <div class="collapse menu-dropdown" id="sidebarDashboards">
            <ul class="nav nav-sm flex-column">
              <li class="nav-item">
                <router-link to="/dashboard/analytics" class="nav-link custom-abc" data-key="t-analytics">
                  {{ $t("t-analytics") }}
                </router-link>
              </li>
              <li class="nav-item">
                <router-link to="/dashboard/crm" class="nav-link" data-key="t-crm">
                  {{ $t("t-crm") }}
                </router-link>
              </li>
              <li class="nav-item">
                <router-link to="/" class="nav-link" data-key="t-ecommerce">
                  {{ $t("t-ecommerce") }}
                </router-link>
              </li>
              <li class="nav-item">
                <router-link to="/dashboard/crypto" class="nav-link" data-key="t-crypto">
                  {{ $t("t-crypto") }}
                </router-link>
              </li>
              <li class="nav-item">
                <router-link to="/dashboard/projects" class="nav-link" data-key="t-projects">
                  {{ $t("t-projects") }}
                </router-link>
              </li>
            </ul>
          </div>
        </li>
        <!-- end Dashboard Menu -->
        <li class="nav-item">
          <a class="nav-link menu-link" href="#sidebarApps" data-bs-toggle="collapse" role="button"
            aria-expanded="false" aria-controls="sidebarApps">
            <i class="ri-apps-2-line"></i>
            <span data-key="t-apps"> {{ $t("t-apps") }}</span>
          </a>
          <div class="collapse menu-dropdown" id="sidebarApps">
            <ul class="nav nav-sm flex-column">
              <li class="nav-item">
                <router-link to="/calendar" class="nav-link" data-key="t-calendar">
                  {{ $t("t-calendar") }}
                </router-link>
              </li>
              <li class="nav-item">
                <router-link to="/chat" class="nav-link" data-key="t-chat">
                  {{ $t("t-chat") }}
                </router-link>
              </li>
              <li class="nav-item">
                <router-link to="/mailbox" class="nav-link" data-key="t-mailbox">
                  {{ $t("t-mailbox") }}
                </router-link>
              </li>
              <li class="nav-item">
                <a href="#sidebarEcommerce" class="nav-link" data-bs-toggle="collapse" role="button"
                  aria-expanded="false" aria-controls="sidebarEcommerce" data-key="t-ecommerce">
                  {{ $t("t-ecommerce") }}
                </a>
                <div class="collapse menu-dropdown" id="sidebarEcommerce">
                  <ul class="nav nav-sm flex-column">
                    <li class="nav-item">
                      <router-link to="/ecommerce/products" class="nav-link" data-key="t-products">
                        {{ $t("t-products") }}
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link to="/ecommerce/product-details" class="nav-link" data-key="t-product-Details">
                        {{ $t("t-product-Details") }}
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link to="/ecommerce/add-product" class="nav-link" data-key="t-create-product">
                        {{ $t("t-create-product") }}
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link to="/ecommerce/orders" class="nav-link" data-key="t-orders">
                        {{ $t("t-orders") }}
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link to="/ecommerce/order-details" class="nav-link" data-key="t-order-details">
                        {{ $t("t-order-details") }}
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link to="/ecommerce/customers" class="nav-link" data-key="t-customers">
                        {{ $t("t-customers") }}
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link to="/ecommerce/shopping-cart" class="nav-link" data-key="t-shopping-cart">
                        {{ $t("t-shopping-cart") }}
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link to="/ecommerce/checkout" class="nav-link" data-key="t-checkout">
                        {{ $t("t-checkout") }}
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link to="/ecommerce/sellers" class="nav-link" data-key="t-sellers">
                        {{ $t("t-sellers") }}
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link to="/ecommerce/seller-details" class="nav-link" data-key="t-sellers-details">
                        {{ $t("t-sellers-details") }}
                      </router-link>
                    </li>
                  </ul>
                </div>
              </li>
              <li class="nav-item">
                <a href="#sidebarProjects" class="nav-link" data-bs-toggle="collapse" role="button"
                  aria-expanded="false" aria-controls="sidebarProjects" data-key="t-projects">
                  {{ $t("t-projects") }}
                </a>
                <div class="collapse menu-dropdown" id="sidebarProjects">
                  <ul class="nav nav-sm flex-column">
                    <li class="nav-item">
                      <router-link to="/apps/projects-list" class="nav-link" data-key="t-list">
                        {{ $t("t-list") }}
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link to="/apps/projects-overview" class="nav-link" data-key="t-overview">
                        {{ $t("t-overview") }}
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link to="/apps/projects-create" class="nav-link" data-key="t-create-project">
                        {{ $t("t-create-project") }}
                      </router-link>
                    </li>
                  </ul>
                </div>
              </li>
              <li class="nav-item">
                <a href="#sidebarTasks" class="nav-link" data-bs-toggle="collapse" role="button" aria-expanded="false"
                  aria-controls="sidebarTasks" data-key="t-tasks">
                  {{ $t("t-tasks") }}
                </a>
                <div class="collapse menu-dropdown" id="sidebarTasks">
                  <ul class="nav nav-sm flex-column">
                    <li class="nav-item">
                      <router-link to="/apps/tasks-kanban" class="nav-link" data-key="t-kanbanboard">
                        {{ $t("t-kanbanboard") }}
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link to="/apps/tasks-list-view" class="nav-link" data-key="t-list-view">
                        {{ $t("t-list-view") }}
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link to="/apps/tasks-details" class="nav-link" data-key="t-task-details">
                        {{ $t("t-task-details") }}
                      </router-link>
                    </li>
                  </ul>
                </div>
              </li>
              <li class="nav-item">
                <a href="#sidebarCRM" class="nav-link" data-bs-toggle="collapse" role="button" aria-expanded="false"
                  aria-controls="sidebarCRM" data-key="t-crm">
                  {{ $t("t-crm") }}
                </a>
                <div class="collapse menu-dropdown" id="sidebarCRM">
                  <ul class="nav nav-sm flex-column">
                    <li class="nav-item">
                      <router-link to="/apps/crm-contacts" class="nav-link" data-key="t-contacts">
                        {{ $t("t-contacts") }}
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link to="/apps/crm-companies" class="nav-link" data-key="t-companies">
                        {{ $t("t-companies") }}
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link to="/apps/crm-deals" class="nav-link" data-key="t-deals">
                        {{ $t("t-deals") }}
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link to="/apps/crm-leads" class="nav-link" data-key="t-leads">
                        {{ $t("t-leads") }}
                      </router-link>
                    </li>
                  </ul>
                </div>
              </li>
              <li class="nav-item">
                <a href="#sidebarCrypto" class="nav-link" data-bs-toggle="collapse" role="button" aria-expanded="false"
                  aria-controls="sidebarCrypto" data-key="t-crypto">
                  {{ $t("t-crypto") }}
                </a>
                <div class="collapse menu-dropdown" id="sidebarCrypto">
                  <ul class="nav nav-sm flex-column">
                    <li class="nav-item">
                      <router-link to="/crypto/transactions" class="nav-link" data-key="t-transactions">
                        {{ $t("t-transactions") }}
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link to="/crypto/buy-sell" class="nav-link" data-key="t-buy-sell">
                        {{ $t("t-buy-sell") }}
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link to="/crypto/orders" class="nav-link" data-key="t-orders">
                        {{ $t("t-orders") }}
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link to="/crypto/wallet" class="nav-link" data-key="t-my-wallet">
                        {{ $t("t-my-wallet") }}
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link to="/crypto/ico" class="nav-link" data-key="t-ico-list">
                        {{ $t("t-ico-list") }}
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link to="/crypto/kyc" class="nav-link" data-key="t-kyc-application">
                        {{ $t("t-kyc-application") }}
                      </router-link>
                    </li>
                  </ul>
                </div>
              </li>
              <li class="nav-item">
                <a href="#sidebarInvoices" class="nav-link" data-bs-toggle="collapse" role="button"
                  aria-expanded="false" aria-controls="sidebarInvoices" data-key="t-invoices">
                  {{ $t("t-invoices") }}
                </a>
                <div class="collapse menu-dropdown" id="sidebarInvoices">
                  <ul class="nav nav-sm flex-column">
                    <li class="nav-item">
                      <router-link to="/invoices/list" class="nav-link" data-key="t-list-view">
                        {{ $t("t-list-view") }}
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link to="/invoices/detail" class="nav-link" data-key="t-details">
                        {{ $t("t-details") }}
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link to="/invoices/create" class="nav-link" data-key="t-create-invoice">
                        {{ $t("t-create-invoice") }}
                      </router-link>
                    </li>
                  </ul>
                </div>
              </li>
              <li class="nav-item">
                <a href="#sidebarTickets" class="nav-link" data-bs-toggle="collapse" role="button" aria-expanded="false"
                  aria-controls="sidebarTickets" data-key="t-supprt-tickets">
                  {{ $t("t-supprt-tickets") }}
                </a>
                <div class="collapse menu-dropdown" id="sidebarTickets">
                  <ul class="nav nav-sm flex-column">
                    <li class="nav-item">
                      <router-link to="/apps/tickets-list" class="nav-link" data-key="t-list-view">
                        {{ $t("t-list-view") }}
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link to="/apps/tickets-details" class="nav-link" data-key="t-ticket-details">
                        {{ $t("t-ticket-details") }}
                      </router-link>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </li>

        <li class="menu-title">
          <i class="ri-more-fill"></i>
          <span data-key="t-pages">{{ $t("t-pages") }}</span>
        </li>

        <li class="nav-item">
          <a class="nav-link menu-link" href="#sidebarAuth" data-bs-toggle="collapse" role="button"
            aria-expanded="false" aria-controls="sidebarAuth">
            <i class="ri-account-circle-line"></i>
            <span data-key="t-authentication">{{
            $t("t-authentication")
            }}</span>
          </a>
          <div class="collapse menu-dropdown" id="sidebarAuth">
            <ul class="nav nav-sm flex-column">
              <li class="nav-item">
                <a href="#sidebarSignIn" class="nav-link" data-bs-toggle="collapse" role="button" aria-expanded="false"
                  aria-controls="sidebarSignIn" data-key="t-signin">{{ $t("t-signin") }}
                </a>
                <div class="collapse menu-dropdown" id="sidebarSignIn">
                  <ul class="nav nav-sm flex-column">
                    <li class="nav-item">
                      <router-link to="/auth/signin-basic" class="nav-link" data-key="t-basic">{{ $t("t-basic") }}
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link to="/auth/signin-cover" class="nav-link" data-key="t-cover">{{ $t("t-cover") }}
                      </router-link>
                    </li>
                  </ul>
                </div>
              </li>
              <li class="nav-item">
                <a href="#sidebarSignUp" class="nav-link" data-bs-toggle="collapse" role="button" aria-expanded="false"
                  aria-controls="sidebarSignUp" data-key="t-signup">{{ $t("t-signup") }}
                </a>
                <div class="collapse menu-dropdown" id="sidebarSignUp">
                  <ul class="nav nav-sm flex-column">
                    <li class="nav-item">
                      <router-link to="/auth/signup-basic" class="nav-link" data-key="t-basic">{{ $t("t-basic") }}
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link to="/auth/signup-cover" class="nav-link" data-key="t-cover">{{ $t("t-cover") }}
                      </router-link>
                    </li>
                  </ul>
                </div>
              </li>

              <li class="nav-item">
                <a href="#sidebarResetPass" class="nav-link" data-bs-toggle="collapse" role="button"
                  aria-expanded="false" aria-controls="sidebarResetPass" data-key="t-password-reset">
                  {{ $t("t-password-reset") }}
                </a>
                <div class="collapse menu-dropdown" id="sidebarResetPass">
                  <ul class="nav nav-sm flex-column">
                    <li class="nav-item">
                      <router-link to="/auth/reset-pwd-basic" class="nav-link" data-key="t-basic">
                        {{ $t("t-basic") }}
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link to="/auth/reset-pwd-cover" class="nav-link" data-key="t-cover">
                        {{ $t("t-cover") }}
                      </router-link>
                    </li>
                  </ul>
                </div>
              </li>

              <li class="nav-item">
                <a href="#sidebarLockScreen" class="nav-link" data-bs-toggle="collapse" role="button"
                  aria-expanded="false" aria-controls="sidebarLockScreen" data-key="t-lock-screen">
                  {{ $t("t-lock-screen") }}
                </a>
                <div class="collapse menu-dropdown" id="sidebarLockScreen">
                  <ul class="nav nav-sm flex-column">
                    <li class="nav-item">
                      <router-link to="/auth/lockscreen-basic" class="nav-link" data-key="t-basic">
                        {{ $t("t-basic") }}
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link to="/auth/lockscreen-cover" class="nav-link" data-key="t-cover">
                        {{ $t("t-cover") }}
                      </router-link>
                    </li>
                  </ul>
                </div>
              </li>

              <li class="nav-item">
                <a href="#sidebarLogout" class="nav-link" data-bs-toggle="collapse" role="button" aria-expanded="false"
                  aria-controls="sidebarLogout" data-key="t-logout">
                  {{ $t("t-logout") }}
                </a>
                <div class="collapse menu-dropdown" id="sidebarLogout">
                  <ul class="nav nav-sm flex-column">
                    <li class="nav-item">
                      <router-link to="/auth/logout-basic" class="nav-link" data-key="t-basic">
                        {{ $t("t-basic") }}
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link to="/auth/logout-cover" class="nav-link" data-key="t-cover">
                        {{ $t("t-cover") }}
                      </router-link>
                    </li>
                  </ul>
                </div>
              </li>
              <li class="nav-item">
                <a href="#sidebarSuccessMsg" class="nav-link" data-bs-toggle="collapse" role="button"
                  aria-expanded="false" aria-controls="sidebarSuccessMsg" data-key="t-success-message">
                  {{ $t("t-success-message") }}
                </a>
                <div class="collapse menu-dropdown" id="sidebarSuccessMsg">
                  <ul class="nav nav-sm flex-column">
                    <li class="nav-item">
                      <router-link to="/auth/success-msg-basic" class="nav-link" data-key="t-basic">
                        {{ $t("t-basic") }}
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link to="/auth/success-msg-cover" class="nav-link" data-key="t-cover">
                        {{ $t("t-cover") }}
                      </router-link>
                    </li>
                  </ul>
                </div>
              </li>
              <li class="nav-item">
                <a href="#sidebarTwoStep" class="nav-link" data-bs-toggle="collapse" role="button" aria-expanded="false"
                  aria-controls="sidebarTwoStep" data-key="t-two-step-verification">
                  {{ $t("t-two-step-verification") }}
                </a>
                <div class="collapse menu-dropdown" id="sidebarTwoStep">
                  <ul class="nav nav-sm flex-column">
                    <li class="nav-item">
                      <router-link to="/auth/twostep-basic" class="nav-link" data-key="t-basic">
                        {{ $t("t-basic") }}
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link to="/auth/twostep-cover" class="nav-link" data-key="t-cover">
                        {{ $t("t-cover") }}
                      </router-link>
                    </li>
                  </ul>
                </div>
              </li>
              <li class="nav-item">
                <a href="#sidebarErrors" class="nav-link" data-bs-toggle="collapse" role="button" aria-expanded="false"
                  aria-controls="sidebarErrors" data-key="t-errors">
                  {{ $t("t-errors") }}
                </a>
                <div class="collapse menu-dropdown" id="sidebarErrors">
                  <ul class="nav nav-sm flex-column">
                    <li class="nav-item">
                      <router-link to="/auth/404-basic" class="nav-link" data-key="t-404-basic">
                        {{ $t("t-404-basic") }}
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link to="/auth/404-cover" class="nav-link" data-key="t-404-cover">
                        {{ $t("t-404-cover") }}
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link to="/auth/404" class="nav-link" data-key="t-404-alt">
                        {{ $t("t-404-alt") }}
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link to="/auth/500" class="nav-link" data-key="t-500">
                        {{ $t("t-500") }}
                      </router-link>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </li>

        <li class="nav-item">
          <a class="nav-link menu-link" href="#sidebarPages" data-bs-toggle="collapse" role="button"
            aria-expanded="false" aria-controls="sidebarPages">
            <i class="ri-pages-line"></i>
            <span data-key="t-pages">{{ $t("t-pages") }}</span>
          </a>
          <div class="collapse menu-dropdown" id="sidebarPages">
            <ul class="nav nav-sm flex-column">
              <li class="nav-item">
                <router-link to="/pages/starter" class="nav-link" data-key="t-starter">{{ $t("t-starter") }}
                </router-link>
              </li>
              <li class="nav-item">
                <a href="#sidebarProfile" class="nav-link" data-bs-toggle="collapse" role="button" aria-expanded="false"
                  aria-controls="sidebarProfile" data-key="t-profile">{{ $t("t-profile") }}
                </a>
                <div class="collapse menu-dropdown" id="sidebarProfile">
                  <ul class="nav nav-sm flex-column">
                    <li class="nav-item">
                      <router-link to="/pages/profile" class="nav-link" data-key="t-simple-page">
                        {{ $t("t-simple-page") }}
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link to="/pages/profile-setting" class="nav-link" data-key="t-settings">
                        {{ $t("t-settings") }}
                      </router-link>
                    </li>
                  </ul>
                </div>
              </li>
              <li class="nav-item">
                <router-link to="/pages/team" class="nav-link" data-key="t-team">
                  {{ $t("t-team") }}
                </router-link>
              </li>
              <li class="nav-item">
                <router-link to="/pages/timeline" class="nav-link" data-key="t-timeline">
                  {{ $t("t-timeline") }}
                </router-link>
              </li>
              <li class="nav-item">
                <router-link to="/pages/faqs" class="nav-link" data-key="t-faqs">
                  {{ $t("t-faqs") }}
                </router-link>
              </li>
              <li class="nav-item">
                <router-link to="/pages/pricing" class="nav-link" data-key="t-pricing">
                  {{ $t("t-pricing") }}
                </router-link>
              </li>

              <li class="nav-item">
                <router-link to="/pages/maintenance" class="nav-link" data-key="t-maintenance">
                  {{ $t("t-maintenance") }}
                </router-link>
              </li>
              <li class="nav-item">
                <router-link to="/pages/coming-soon" class="nav-link" data-key="t-coming-soon">
                  {{ $t("t-coming-soon") }}
                </router-link>
              </li>
              <li class="nav-item">
                <router-link to="/pages/sitemap" class="nav-link" data-key="t-sitemap">
                  {{ $t("t-sitemap") }}
                </router-link>
              </li>
              <li class="nav-item">
                <router-link to="/pages/search-results" class="nav-link" data-key="t-search-results">
                  {{ $t("t-search-results") }}
                </router-link>
              </li>
            </ul>
          </div>
        </li>




        <li class="menu-title">
          <i class="ri-more-fill"></i>
          <span data-key="t-components">{{ $t("t-components") }}</span>
        </li>

        <li class="nav-item">
          <a class="nav-link menu-link" href="#sidebarUI" data-bs-toggle="collapse" role="button" aria-expanded="false"
            aria-controls="sidebarUI">
            <i class="ri-pencil-ruler-2-line"></i>
            <span data-key="t-base-ui">{{ $t("t-base-ui") }}</span>
          </a>
          <div class="collapse menu-dropdown mega-dropdown-menu" id="sidebarUI">
            <div class="row">
              <div class="col-lg-4">
                <ul class="nav nav-sm flex-column">
                  <li class="nav-item">
                    <router-link to="/ui/alerts" class="nav-link" data-key="t-alerts">{{ $t("t-alerts") }}</router-link>
                  </li>
                  <li class="nav-item">
                    <router-link to="/ui/badges" class="nav-link" data-key="t-badges">{{ $t("t-badges") }}</router-link>
                  </li>
                  <li class="nav-item">
                    <router-link to="/ui/buttons" class="nav-link" data-key="t-buttons">{{ $t("t-buttons") }}
                    </router-link>
                  </li>
                  <li class="nav-item">
                    <router-link to="/ui/colors" class="nav-link" data-key="t-colors">{{ $t("t-colors") }}</router-link>
                  </li>
                  <li class="nav-item">
                    <router-link to="/ui/cards" class="nav-link" data-key="t-cards">{{ $t("t-cards") }}</router-link>
                  </li>
                  <li class="nav-item">
                    <router-link to="/ui/carousel" class="nav-link" data-key="t-carousel">{{ $t("t-carousel") }}
                    </router-link>
                  </li>
                  <li class="nav-item">
                    <router-link to="/ui/dropdowns" class="nav-link" data-key="t-dropdowns">{{ $t("t-dropdowns") }}
                    </router-link>
                  </li>
                  <li class="nav-item">
                    <router-link to="/ui/grid" class="nav-link" data-key="t-grid">{{ $t("t-grid") }}</router-link>
                  </li>
                </ul>
              </div>
              <div class="col-lg-4">
                <ul class="nav nav-sm flex-column">
                  <li class="nav-item">
                    <router-link to="/ui/images" class="nav-link" data-key="t-images">{{ $t("t-images") }}</router-link>
                  </li>
                  <li class="nav-item">
                    <router-link to="/ui/tabs" class="nav-link" data-key="t-tabs">{{ $t("t-tabs") }}</router-link>
                  </li>
                  <li class="nav-item">
                    <router-link to="/ui/accordions" class="nav-link" data-key="t-accordion-collapse">
                      {{ $t("t-accordion-collapse") }}</router-link>
                  </li>
                  <li class="nav-item">
                    <router-link to="/ui/modals" class="nav-link" data-key="t-modals">{{ $t("t-modals") }}</router-link>
                  </li>
                  <li class="nav-item">
                    <router-link to="/ui/offcanvas" class="nav-link" data-key="t-offcanvas">{{ $t("t-offcanvas") }}
                    </router-link>
                  </li>
                  <li class="nav-item">
                    <router-link to="/ui/placeholders" class="nav-link" data-key="t-placeholders">
                      {{ $t("t-placeholders") }}</router-link>
                  </li>
                  <li class="nav-item">
                    <router-link to="/ui/progress" class="nav-link" data-key="t-progress">{{ $t("t-progress") }}
                    </router-link>
                  </li>
                  <li class="nav-item">
                    <router-link to="/ui/notifications" class="nav-link" data-key="t-notifications">
                      {{ $t("t-notifications") }}</router-link>
                  </li>
                </ul>
              </div>
              <div class="col-lg-4">
                <ul class="nav nav-sm flex-column">
                  <li class="nav-item">
                    <router-link to="/ui/media" class="nav-link" data-key="t-media-object">{{ $t("t-media-object") }}
                    </router-link>
                  </li>
                  <li class="nav-item">
                    <router-link to="/ui/embed-video" class="nav-link" data-key="t-embed-video">
                      {{ $t("t-embed-video") }}</router-link>
                  </li>
                  <li class="nav-item">
                    <router-link to="/ui/typography" class="nav-link" data-key="t-typography">{{ $t("t-typography") }}
                    </router-link>
                  </li>
                  <li class="nav-item">
                    <router-link to="/ui/lists" class="nav-link" data-key="t-lists">{{ $t("t-lists") }}</router-link>
                  </li>
                  <li class="nav-item">
                    <router-link to="/ui/general" class="nav-link" data-key="t-general">{{ $t("t-general") }}
                    </router-link>
                  </li>
                  <li class="nav-item">
                    <router-link to="/ui/ribbons" class="nav-link" data-key="t-ribbons">{{ $t("t-ribbons") }}
                    </router-link>
                  </li>
                  <li class="nav-item">
                    <router-link to="/ui/utilities" class="nav-link" data-key="t-utilities">{{ $t("t-utilities") }}
                    </router-link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </li>

        <li class="nav-item">
          <a class="nav-link menu-link" href="#sidebarAdvanceUI" data-bs-toggle="collapse" role="button"
            aria-expanded="false" aria-controls="sidebarAdvanceUI">
            <i class="ri-stack-line"></i>
            <span data-key="t-advance-ui">{{ $t("t-advance-ui") }}</span>
            <span class="badge badge-pill bg-success" data-key="t-new">{{
            $t("t-new")
            }}</span>
          </a>
          <div class="collapse menu-dropdown" id="sidebarAdvanceUI">
            <ul class="nav nav-sm flex-column">
              <li class="nav-item">
                <router-link to="/advance-ui/sweetalerts" class="nav-link" data-key="t-sweet-alerts">
                  {{ $t("t-sweet-alerts") }}</router-link>
              </li>

              <li class="nav-item">
                <router-link to="/advance-ui/scrollbar" class="nav-link" data-key="t-scrollbar">{{ $t("t-scrollbar") }}
                </router-link>
              </li>
              <li class="nav-item">
                <router-link to="/advance-ui/animation" class="nav-link" data-key="t-animation">{{ $t("t-animation") }}
                </router-link>
              </li>
              <li class="nav-item">
                <router-link to="/advance-ui/swiper" class="nav-link" data-key="t-swiper-slider">
                  {{ $t("t-swiper-slider") }}</router-link>
              </li>

              <li class="nav-item">
                <router-link to="/advance-ui/highlight" class="nav-link" data-key="t-highlight">{{ $t("t-highlight") }}
                </router-link>
              </li>
              <li class="nav-item">
                <router-link to="/advance-ui/scrollspy" class="nav-link" data-key="t-scrollSpy">{{ $t("t-scrollSpy") }}
                </router-link>
              </li>
            </ul>
          </div>
        </li>

        <li class="nav-item">
          <router-link class="nav-link menu-link" to="/widgets">
            <i class="ri-honour-line"></i>
            <span data-key="t-widgets">{{ $t("t-widgets") }}</span>
          </router-link>
        </li>

        <li class="nav-item">
          <a class="nav-link menu-link" href="#sidebarForms" data-bs-toggle="collapse" role="button"
            aria-expanded="false" aria-controls="sidebarForms">
            <i class="ri-file-list-3-line"></i>
            <span data-key="t-forms">{{ $t("t-forms") }}</span>
          </a>
          <div class="collapse menu-dropdown" id="sidebarForms">
            <ul class="nav nav-sm flex-column">
              <li class="nav-item">
                <router-link to="/form/elements" class="nav-link" data-key="t-basic-elements">
                  {{ $t("t-basic-elements") }}</router-link>
              </li>
              <li class="nav-item">
                <router-link to="/form/select" class="nav-link" data-key="t-form-select">{{ $t("t-form-select") }}
                </router-link>
              </li>
              <li class="nav-item">
                <router-link to="/form/checkboxs-radios" class="nav-link" data-key="t-checkboxs-radios">
                  {{ $t("t-checkboxs-radios") }}</router-link>
              </li>
              <li class="nav-item">
                <router-link to="/form/pickers" class="nav-link" data-key="t-pickers">
                  {{ $t("t-pickers") }}
                </router-link>
              </li>
              <li class="nav-item">
                <router-link to="/form/masks" class="nav-link" data-key="t-input-masks">{{ $t("t-input-masks") }}
                </router-link>
              </li>
              <li class="nav-item">
                <router-link to="/form/advanced" class="nav-link" data-key="t-advanced">
                  {{ $t("t-advanced") }}</router-link>
              </li>
              <li class="nav-item">
                <router-link to="/form/range-sliders" class="nav-link" data-key="t-range-slider">
                  {{ $t("t-range-slider") }}
                </router-link>
              </li>
              <li class="nav-item">
                <router-link to="/form/validation" class="nav-link" data-key="t-validation">{{ $t("t-validation") }}
                </router-link>
              </li>
              <li class="nav-item">
                <router-link to="/form/wizard" class="nav-link" data-key="t-wizard">{{ $t("t-wizard") }}</router-link>
              </li>
              <li class="nav-item">
                <router-link to="/form/editors" class="nav-link" data-key="t-editors">{{ $t("t-editors") }}
                </router-link>
              </li>
              <li class="nav-item">
                <router-link to="/form/file-uploads" class="nav-link" data-key="t-file-uploads">
                  {{ $t("t-file-uploads") }}</router-link>
              </li>
              <li class="nav-item">
                <router-link to="/form/layouts" class="nav-link" data-key="t-form-layouts">{{ $t("t-form-layouts") }}
                </router-link>
              </li>
            </ul>
          </div>
        </li>

        <li class="nav-item">
          <a class="nav-link menu-link" href="#sidebarTables" data-bs-toggle="collapse" role="button"
            aria-expanded="false" aria-controls="sidebarTables">
            <i class="ri-layout-grid-line"></i>
            <span data-key="t-tables">{{ $t("t-tables") }}</span>
          </a>
          <div class="collapse menu-dropdown" id="sidebarTables">
            <ul class="nav nav-sm flex-column">
              <li class="nav-item">
                <router-link to="/tables/basic" class="nav-link" data-key="t-basic-tables">{{ $t("t-basic-tables") }}
                </router-link>
              </li>
              <li class="nav-item">
                <router-link to="/tables/gridjs" class="nav-link" data-key="t-grid-js">{{ $t("t-grid-js") }}
                </router-link>
              </li>
            </ul>
          </div>
        </li>

        <li class="nav-item">
          <a class="nav-link menu-link" href="#sidebarCharts" data-bs-toggle="collapse" role="button"
            aria-expanded="false" aria-controls="sidebarCharts">
            <i class="ri-pie-chart-line"></i>
            <span data-key="t-charts">{{ $t("t-charts") }}</span>
          </a>
          <div class="collapse menu-dropdown" id="sidebarCharts">
            <ul class="nav nav-sm flex-column">
              <li class="nav-item">
                <a href="#sidebarApexcharts" class="nav-link" data-bs-toggle="collapse" role="button"
                  aria-expanded="false" aria-controls="sidebarApexcharts" data-key="t-apexcharts">
                  {{ $t("t-apexcharts") }}
                </a>
                <div class="collapse menu-dropdown" id="sidebarApexcharts">
                  <ul class="nav nav-sm flex-column">
                    <li class="nav-item">
                      <router-link to="/charts/apex-line" class="nav-link" data-key="t-line">
                        {{ $t("t-line") }}
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link to="/charts/apex-area" class="nav-link" data-key="t-area">
                        {{ $t("t-area") }}
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link to="/charts/apex-column" class="nav-link" data-key="t-column">
                        {{ $t("t-column") }}
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link to="/charts/apex-bar" class="nav-link" data-key="t-bar">
                        {{ $t("t-bar") }}
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link to="/charts/apex-mixed" class="nav-link" data-key="t-mixed">
                        {{ $t("t-mixed") }}
                      </router-link>
                    </li>

                    <li class="nav-item">
                      <router-link to="/charts/apex-candlestick" class="nav-link" data-key="t-candlstick">
                        {{ $t("t-candlstick") }}
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link to="/charts/apex-boxplot" class="nav-link" data-key="t-boxplot">
                        {{ $t("t-boxplot") }}
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link to="/charts/apex-bubble" class="nav-link" data-key="t-bubble">
                        {{ $t("t-bubble") }}
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link to="/charts/apex-scatter" class="nav-link" data-key="t-scatter">
                        {{ $t("t-scatter") }}
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link to="/charts/apex-heatmap" class="nav-link" data-key="t-heatmap">
                        {{ $t("t-heatmap") }}
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link to="/charts/apex-treemap" class="nav-link" data-key="t-treemap">
                        {{ $t("t-treemap") }}
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link to="/charts/apex-pie" class="nav-link" data-key="t-pie">
                        {{ $t("t-pie") }}
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link to="/charts/apex-radialbar" class="nav-link" data-key="t-radialbar">
                        {{ $t("t-radialbar") }}
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link to="/charts/apex-radar" class="nav-link" data-key="t-radar">
                        {{ $t("t-radar") }}
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link to="/charts/apex-polararea" class="nav-link" data-key="t-polar-area">
                        {{ $t("t-polar-area") }}
                      </router-link>
                    </li>
                  </ul>
                </div>
              </li>
              <li class="nav-item">
                <router-link to="/charts/chartjs" class="nav-link" data-key="t-chartjs">{{ $t("t-chartjs") }}
                </router-link>
              </li>
              <li class="nav-item">
                <router-link to="/charts/echart" class="nav-link" data-key="t-echarts">{{ $t("t-echarts") }}
                </router-link>
              </li>
            </ul>
          </div>
        </li>

        <li class="nav-item">
          <a class="nav-link menu-link" href="#sidebarIcons" data-bs-toggle="collapse" role="button"
            aria-expanded="false" aria-controls="sidebarIcons">
            <i class="ri-compasses-2-line"></i>
            <span data-key="t-icons">{{ $t("t-icons") }}</span>
          </a>
          <div class="collapse menu-dropdown" id="sidebarIcons">
            <ul class="nav nav-sm flex-column">
              <li class="nav-item">
                <router-link to="/icons/remix" class="nav-link" data-key="t-remix">{{ $t("t-remix") }}</router-link>
              </li>
              <li class="nav-item">
                <router-link to="/icons/boxicons" class="nav-link" data-key="t-boxicons">{{ $t("t-boxicons") }}
                </router-link>
              </li>
              <li class="nav-item">
                <router-link to="/icons/materialdesign" class="nav-link" data-key="t-material-design">
                  {{ $t("t-material-design") }}</router-link>
              </li>
              <li class="nav-item">
                <router-link to="/icons/lineawesome" class="nav-link" data-key="t-line-awesome">
                  {{ $t("t-line-awesome") }}</router-link>
              </li>
              <li class="nav-item">
                <router-link to="/icons/feather" class="nav-link" data-key="t-feather">{{ $t("t-feather") }}
                </router-link>
              </li>
            </ul>
          </div>
        </li>

        <li class="nav-item">
          <a class="nav-link menu-link" href="#sidebarMaps" data-bs-toggle="collapse" role="button"
            aria-expanded="false" aria-controls="sidebarMaps">
            <i class="ri-map-pin-line"></i>
            <span data-key="t-maps">{{ $t("t-maps") }}</span>
          </a>
          <div class="collapse menu-dropdown" id="sidebarMaps">
            <ul class="nav nav-sm flex-column">
              <li class="nav-item">
                <router-link to="/maps/google" class="nav-link" data-key="t-google">
                  {{ $t("t-google") }}
                </router-link>
              </li>
              <li class="nav-item">
                <router-link to="/maps/leaflet" class="nav-link" data-key="t-leaflet">
                  {{ $t("t-leaflet") }}
                </router-link>
              </li>
            </ul>
          </div>
        </li>

        <li class="nav-item">
          <a class="nav-link menu-link" href="#sidebarMultilevel" data-bs-toggle="collapse" role="button"
            aria-expanded="false" aria-controls="sidebarMultilevel">
            <i class="ri-share-line"></i>
            <span data-key="t-multi-level">{{ $t("t-multi-level") }}</span>
          </a>
          <div class="collapse menu-dropdown" id="sidebarMultilevel">
            <ul class="nav nav-sm flex-column">
              <li class="nav-item">
                <a href="#" class="nav-link" data-key="t-level-1.1">
                  {{ $t("t-level-1.1") }}
                </a>
              </li>
              <li class="nav-item">
                <a href="#sidebarAccount" class="nav-link" data-bs-toggle="collapse" role="button" aria-expanded="false"
                  aria-controls="sidebarAccount" data-key="t-level-1.2">
                  {{ $t("t-level-1.2") }}
                </a>
                <div class="collapse menu-dropdown" id="sidebarAccount">
                  <ul class="nav nav-sm flex-column">
                    <li class="nav-item">
                      <a href="#" class="nav-link" data-key="t-level-2.1">
                        {{ $t("t-level-2.1") }}
                      </a>
                    </li>
                    <li class="nav-item">
                      <a href="#sidebarCrm" class="nav-link" data-bs-toggle="collapse" role="button"
                        aria-expanded="false" aria-controls="sidebarCrm" data-key="t-level-2.2">
                        {{ $t("t-level-2.2") }}
                      </a>
                      <div class="collapse menu-dropdown" id="sidebarCrm">
                        <ul class="nav nav-sm flex-column">
                          <li class="nav-item">
                            <a href="#" class="nav-link" data-key="t-level-3.1">
                              {{ $t("t-level-3.1") }}
                            </a>
                          </li>
                          <li class="nav-item">
                            <a href="#" class="nav-link" data-key="t-level-3.2">
                              {{ $t("t-level-3.2") }}
                            </a>
                          </li>
                        </ul>
                      </div>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </li>
      </SimpleBar>
    </template>

    
  </div>
  <!-- Sidebar -->
</template>